// app.js
import React from "react";
import { Route, Routes } from "react-router-dom";
import ExpatFormPage from "./pages/ExpatFormPage";
import LandingPage from "./pages/LandingPage";

export default function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/expatFormPage" element={<ExpatFormPage />} />
      </Routes>
    </>
  );
}
