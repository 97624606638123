import { ButtonBase, Paper, Snackbar, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ArcMap from "../components/ArcMap";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import { create } from "d3";
import { createTravelRecords } from "../components/dataFunctions";
import ChartComponent from "./ChartComponent";

function LandingPage() {
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(new Date(1979, 12, 1));
  const [endDate, setEndDate] = useState(new Date());
  const [travelRecords, setTravelRecords] = useState(createTravelRecords());

  return (
    <div
      style={{
        display: "grid",
        padding: "12px",
        gap: "24px",
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
        gridTemplateRows: "10% 55% 12px auto",
        backgroundImage: "radial-gradient(  #52938F 10%, #0D1014)",
      }}
    >
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "2fr 1fr",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "12px ",
          color: "white",
        }}
      >
        <div>
          <Typography variant="h5">Where Did We Go</Typography>
          <Typography variant="caption">Visualising the diaspora of South Africa</Typography>
        </div>

        <ButtonBase
          sx={{
            justifySelf: "end",
            display: "grid",
            gridTemplateRows: "auto auto",
            justifyItems: "center",
            color: "white",
          }}
          onClick={() => navigate("/expatFormPage")}
        >
          <PersonAddAlt1Icon />
          <Typography variant="caption">Add my data</Typography>
        </ButtonBase>
      </div>

      <div style={{ display: "grid", justifyItems: "center", gridTemplateRows: "auto 100px" }}>
        <ArcMap startDate={startDate} endDate={endDate} travelRecords={travelRecords} />

        {/* <Paper
          sx={{
            position: "absolute",
            top: "-20px",
            right: "-20px",
            height: "100px",
            width: "100px",
            borderRadius: "50%",
            display: "grid",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            backgroundColor: "#1B6566",
            color: "white",
          }}
        >
          <Typography variant="caption">
            Total respondents <strong style={{ fontSize: "larger" }}>3456</strong>
          </Typography>
        </Paper> */}
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={true}
          sx={{
            "& .MuiPaper-root": {
              backgroundColor: "#ff4141",
            },
          }}
          message={
            <div style={{ display: "grid", justifyItems: "center" }}>
              <Typography variant="body1">THIS IS NOT ACTUAL DATA</Typography>
              <Typography variant="body1">This is only test data for development</Typography>
            </div>
          }
          onClose={() => {}}
        />
      </div>
    </div>
  );
}

export default LandingPage;
