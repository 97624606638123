export const southAfricanTowns = [
  { latitude: -33.9249, longitude: 18.4241 }, // Cape Town
  { latitude: -26.2041, longitude: 28.0473 }, // Johannesburg
  { latitude: -29.8587, longitude: 31.0218 }, // Durban
  { latitude: -33.7139, longitude: 25.5207 }, // Port Elizabeth
  { latitude: -26.7107, longitude: 27.0974 }, // Bloemfontein
  { latitude: -33.0277, longitude: 27.8546 }, // East London
  { latitude: -29.12, longitude: 26.214 }, // Kimberley
  { latitude: -30.5595, longitude: 22.9375 }, // Upington
  { latitude: -32.8902, longitude: 27.9586 }, // King William's Town
  { latitude: -28.7416, longitude: 24.771 }, // Kuruman
  { latitude: -29.603, longitude: 30.3776 }, // Pietermaritzburg
  { latitude: -33.9668, longitude: 25.588 }, // Uitenhage
  { latitude: -34.0029, longitude: 20.4649 }, // George
  { latitude: -28.291, longitude: 31.4169 }, // Richards Bay
  { latitude: -30.1597, longitude: 30.5805 }, // Newcastle
  { latitude: -25.7598, longitude: 28.1985 }, // Pretoria
  { latitude: -25.7461, longitude: 28.1881 }, // Pretoria (different location)
  { latitude: -33.5891, longitude: 26.891 }, // Grahamstown
  { latitude: -29.5219, longitude: 30.9126 }, // Howick
  { latitude: -32.2826, longitude: 24.5391 }, // Graaff-Reinet
  // Add more towns as needed
];

export const globalTowns = [
  { latitude: 51.5074, longitude: -0.1278 }, // London, United Kingdom
  { latitude: 48.8566, longitude: 2.3522 }, // Paris, France
  { latitude: 55.7558, longitude: 37.6173 }, // Moscow, Russia
  { latitude: 55.6761, longitude: 12.5683 }, // Copenhagen, Denmark
  { latitude: 41.9028, longitude: 12.4964 }, // Rome, Italy
  { latitude: 52.52, longitude: 13.405 }, // Berlin, Germany
  { latitude: 59.3293, longitude: 18.0686 }, // Stockholm, Sweden
  { latitude: 52.3667, longitude: 4.8945 }, // Amsterdam, Netherlands
  { latitude: 48.2082, longitude: 16.3738 }, // Vienna, Austria
  { latitude: 50.8503, longitude: 4.3517 }, // Brussels, Belgium
  { latitude: 38.7223, longitude: -9.1393 }, // Lisbon, Portugal
  { latitude: 41.3851, longitude: 2.1734 }, // Barcelona, Spain
  { latitude: 60.1695, longitude: 24.9354 }, // Helsinki, Finland
  { latitude: 45.815, longitude: 15.9819 }, // Zagreb, Croatia
  { latitude: 52.2297, longitude: 21.0122 }, // Warsaw, Poland
  { latitude: 47.3769, longitude: 8.5417 }, // Zurich, Switzerland
  { latitude: 41.0082, longitude: 28.9784 }, // Istanbul, Turkey
  { latitude: 48.1351, longitude: 11.582 }, // Munich, Germany
  { latitude: 53.3498, longitude: -6.2603 }, // Dublin, Ireland
  { latitude: 45.4215, longitude: -75.6972 }, // Ottawa, Canada (different location)
  { latitude: 9.082, longitude: 8.6753 }, // Abuja, Nigeria
  { latitude: 15.5007, longitude: 32.5599 }, // Khartoum, Sudan
  { latitude: 12.6392, longitude: -8.0029 }, // Bamako, Mali
  { latitude: 9.6412, longitude: -13.5784 }, // Freetown, Sierra Leone
  { latitude: 1.2921, longitude: 36.8219 }, // Nairobi, Kenya
  { latitude: 10.8231, longitude: 106.6297 }, // Ho Chi Minh City, Vietnam
  { latitude: 14.6928, longitude: -17.4467 }, // Banjul, Gambia
  { latitude: 6.5244, longitude: 3.3792 }, // Lagos, Nigeria
  { latitude: 14.6928, longitude: -17.4467 }, // Banjul, Gambia (different location)
  { latitude: 11.5564, longitude: 43.1459 }, // Djibouti City, Djibouti
  { latitude: 15.5527, longitude: 48.5164 }, // Sana'a, Yemen
  { latitude: 13.4399, longitude: -16.6775 }, // Bissau, Guinea-Bissau
  { latitude: 25.276987, longitude: 51.520008 }, // Doha, Qatar
  { latitude: 5.6037, longitude: -0.187 }, // Accra, Ghana
  { latitude: 12.1048, longitude: -86.2362 }, // Managua, Nicaragua
  { latitude: -1.2921, longitude: 36.8219 }, // Nairobi, Kenya (different location)
  { latitude: 11.8251, longitude: 42.5903 }, // Djibouti City, Djibouti (different location)
  { latitude: 9.0, longitude: 38.75 }, // Addis Ababa, Ethiopia
  { latitude: 18.0791, longitude: -15.9785 }, // Nouakchott, Mauritania
  { latitude: 17.3616, longitude: -62.7759 }, // St. John's, Antigua and Barbuda
  // Add more towns as needed
  { latitude: -33.8688, longitude: 151.2093 }, // Sydney
  { latitude: -37.8136, longitude: 144.9631 }, // Melbourne
  { latitude: -31.9505, longitude: 115.8605 }, // Perth
  { latitude: -27.4698, longitude: 153.0251 }, // Brisbane
  { latitude: -35.2809, longitude: 149.13 }, // Canberra
  { latitude: -41.2865, longitude: 174.7762 }, // Wellington
  { latitude: -41.2865, longitude: 174.7762 }, // Wellington (different location)
  { latitude: -36.8485, longitude: 174.7633 }, // Auckland
  { latitude: -43.5321, longitude: 172.6362 }, // Christchurch
  { latitude: -33.8675, longitude: 151.207 }, // Sydney (different location)
  { latitude: -34.9285, longitude: 138.6007 }, // Adelaide
  { latitude: -31.9505, longitude: 115.8605 }, // Perth (different location)
  { latitude: -37.4698, longitude: 144.9631 }, // Melbourne (different location)
  { latitude: -41.2865, longitude: 174.7762 }, // Wellington (different location)
  { latitude: -28.0167, longitude: 153.4 }, // Gold Coast
  { latitude: -12.4634, longitude: 130.8456 }, // Darwin
  { latitude: -19.26, longitude: 146.8183 }, // Townsville
  { latitude: -42.8821, longitude: 147.3272 }, // Hobart
  { latitude: -21.1525, longitude: 149.2025 }, // Mackay
  { latitude: -37.5622, longitude: 143.8503 }, // Ballarat
  // Add more towns as needed
  { latitude: 39.9042, longitude: 116.4074 }, // Beijing
  { latitude: 35.6895, longitude: 139.6917 }, // Tokyo
  { latitude: 6.5244, longitude: 3.3792 }, // Lagos
  { latitude: 14.5995, longitude: 120.9842 }, // Manila
  { latitude: 28.6139, longitude: 77.209 }, // New Delhi
  { latitude: 33.6844, longitude: -117.8265 }, // Irvine
  { latitude: 41.9028, longitude: 12.4964 }, // Rome
  { latitude: 25.276987, longitude: 51.520008 }, // Doha
  { latitude: 13.7563, longitude: 100.5018 }, // Bangkok
  { latitude: 31.5497, longitude: 74.3436 }, // Lahore
  { latitude: 23.8103, longitude: 90.4125 }, // Dhaka
  { latitude: 20.5937, longitude: 78.9629 }, // India (Center)
  { latitude: 35.6894, longitude: 139.6917 }, // Yokohama
  { latitude: 1.3521, longitude: 103.8198 }, // Singapore
  { latitude: 25.276987, longitude: 51.520008 }, // Doha (different location)
  { latitude: 6.9271, longitude: 79.8612 }, // Colombo
  { latitude: 3.139, longitude: 101.6869 }, // Kuala Lumpur
  { latitude: 22.3964, longitude: 114.1095 }, // Hong Kong
  { latitude: 27.7172, longitude: 85.324 }, // Kathmandu
  { latitude: 25.0329, longitude: 121.5654 }, // Taipei
  // Add more towns as needed
  { latitude: -34.6037, longitude: -58.3816 }, // Buenos Aires
  { latitude: -23.5505, longitude: -46.6333 }, // São Paulo
  { latitude: -12.0464, longitude: -77.0428 }, // Lima
  { latitude: -33.4489, longitude: -70.6693 }, // Santiago
  { latitude: -2.17, longitude: -79.9224 }, // Quito
  { latitude: -15.7942, longitude: -47.8822 }, // Brasília
  { latitude: 4.7109, longitude: -74.0721 }, // Bogotá
  { latitude: -34.8941, longitude: -56.0675 }, // Montevideo
  { latitude: -22.9068, longitude: -43.1729 }, // Rio de Janeiro
  { latitude: -0.1807, longitude: -78.4678 }, // Guayaquil
  { latitude: -16.5, longitude: -68.15 }, // La Paz
  { latitude: -23.5505, longitude: -46.6333 }, // São Paulo (different location)
  { latitude: -25.3005, longitude: -57.6362 }, // Asunción
  { latitude: -15.7801, longitude: -47.9292 }, // Brasília (different location)
  { latitude: -3.7172, longitude: -38.543 }, // Fortaleza
  { latitude: 10.4806, longitude: -66.9036 }, // Caracas
  { latitude: -2.2426, longitude: -80.8987 }, // Cuenca
  { latitude: -34.8556, longitude: -56.1711 }, // Montevideo (different location)
  { latitude: -23.3045, longitude: -51.1694 }, // Curitiba
  { latitude: -10.9472, longitude: -37.0731 }, // Aracaju
  // Add more towns as needed
  { latitude: 45.4215, longitude: -75.6972 }, // Ottawa
  { latitude: 40.7128, longitude: -74.006 }, // New York
  { latitude: 34.0522, longitude: -118.2437 }, // Los Angeles
  { latitude: 49.2827, longitude: -123.1207 }, // Vancouver
  { latitude: 41.8781, longitude: -87.6298 }, // Chicago
  { latitude: 29.7604, longitude: -95.3698 }, // Houston
  { latitude: 32.7157, longitude: -117.1611 }, // San Diego
  { latitude: 37.7749, longitude: -122.4194 }, // San Francisco
  { latitude: 36.7783, longitude: -119.4179 }, // Fresno
  { latitude: 35.6828, longitude: -105.9378 }, // Santa Fe
  { latitude: 19.4326, longitude: -99.1332 }, // Mexico City
  { latitude: 37.0902, longitude: -95.7129 }, // United States (Center)
  { latitude: 49.8951, longitude: -97.1384 }, // Winnipeg
  { latitude: 53.5461, longitude: -113.4938 }, // Edmonton
  { latitude: 30.2672, longitude: -97.7431 }, // Austin
  { latitude: 38.9072, longitude: -77.0369 }, // Washington D.C.
  { latitude: 25.7617, longitude: -80.1918 }, // Miami
  { latitude: 39.9526, longitude: -75.1652 }, // Philadelphia
  { latitude: 43.6532, longitude: -79.3832 }, // Toronto
  { latitude: 37.3382, longitude: -121.8863 }, // San Jose
  // Add more towns as needed
];
