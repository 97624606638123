// app.js
import React, { useEffect, useState } from "react";
import DeckGL from "@deck.gl/react";
import { InteractiveMap } from "react-map-gl";
import { ArcLayer, HexagonLayer, ScatterplotLayer } from "deck.gl";
// import arcData from "../dynamoData.json";
import { Paper } from "@mui/material";
import mapStyle from "../mapStyle.json";
import maplibregl from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";
import { differenceInCalendarMonths } from "date-fns";
import { useAnimation, useTimestepper } from "../pages/customHooks";
import ArcMapControls from "./ArcMapControls";
import ChartComponent from "../pages/ChartComponent";

// filter only the lat and long

// arcData = arcData.filter(
//   (record) =>
//     record.Item?.country2_ISO_code?.S === "ZA" ||
//     record.Item?.country3_ISO_code?.S === "ZA" ||
//     record.Item?.country3_ISO_code?.S === "ZA"
// );

// arcData = arcData.splice(0, 10);

// ============================================================= Animated Arc Layers

// ============================================================= Layers

// const hexLayer = new HexagonLayer({
//   id: "hexLayer",
//   data: arcData,
//   pickable: true,
//   extruded: true,
//   radius: 50000,
//   elevationScale: 1000,
//   getPosition: (d) => d.to.coordinates,
// });

// DeckGL react component
export default function ArcMap({ startDate, endDate, travelRecords }) {
  const [maxTimerVal, setMaxTimerVal] = useState(differenceInCalendarMonths(endDate, startDate));
  const [timeStepperVal, timerSpeed, timerRunning, startTimer, stopTimer, resetTimer, restartTimer, placeTimer] =
    useTimestepper(maxTimerVal);
  const [dynamicArcData, setDynamicArcData] = useState([]);
  const [staticArcData, setStaticArcData] = useState([]);
  const [animationProgress, startAnimation] = useAnimation(timerSpeed);
  const [deckLayers, setDeckLayers] = useState();
  const [viewState, setViewState] = useState({
    longitude: 30.0,
    latitude: 30.0,
    zoom: 1.2,
    pitch: 50,
    bearing: 0,
  });

  const pushToArcData = () => {
    setDynamicArcData(travelRecords[timeStepperVal]);
    let newStaticArcData = travelRecords.slice(0, [timeStepperVal]);
    newStaticArcData = newStaticArcData.flat();
    setStaticArcData(newStaticArcData);
  };

  class ArcBrushingLayer extends ArcLayer {
    // custom shader with step function to create opacity gradient with colorA and colorB
    // More at https://thebookofshaders.com/05/
    getShaders() {
      return Object.assign({}, super.getShaders(), {
        inject: {
          "vs:#decl": `uniform float coef;`,
          "vs:#main-end": `
            if (coef > 0.0) {
              vec4 pct = vec4(segmentRatio);
              pct.a = step(coef, segmentRatio);
              vec4 colorA = instanceTargetColors;
              vec4 colorB = vec4(instanceTargetColors.r, instanceTargetColors.g, instanceTargetColors.b, 0.0);
              vec4 color = mix(instanceSourceColors, colorB, pct.a);
              vColor = color;
              DECKGL_FILTER_COLOR(vColor, geometry);
            }
          `,
        },
      });
    }

    draw(opts) {
      const { coef } = this.props;

      this.state.model.setUniforms({ coef });
      super.draw(opts);
    }
  }

  let arcLayerDynamic = new ArcBrushingLayer({
    id: "arc-layer-dynamic",
    data: dynamicArcData,
    getSourcePosition: (d) => {
      return [parseFloat(d.Item.origin_TownLoc.M.longitude.N), parseFloat(d.Item.origin_TownLoc.M.latitude.N)];
    },
    getTargetPosition: (d) => {
      return [parseFloat(d.Item.country1_TownLoc.M.longitude.N), parseFloat(d.Item.country1_TownLoc.M.latitude.N)];
    },
    getSourceColor: (d) => [255, 0, 0],
    getTargetColor: (d) => [255, 0, 0],
    getTilt: (d) => d.tilt,
    widthUnits: "pixels",
    getWidth: 1,
    coef: animationProgress,

    getHeight: (d) => d.t * 10000, // Adjust this for desired arc height
  });

  let arcLayerStatic = new ArcLayer({
    id: "arc-layer-static",
    data: staticArcData,
    getSourcePosition: (d) => {
      return [parseFloat(d.Item.origin_TownLoc.M.longitude.N), parseFloat(d.Item.origin_TownLoc.M.latitude.N)];
    },
    getTargetPosition: (d) => {
      return [parseFloat(d.Item.country1_TownLoc.M.longitude.N), parseFloat(d.Item.country1_TownLoc.M.latitude.N)];
    },
    getSourceColor: (d) => [0, 0, 255, 30],
    getTargetColor: (d) => [0, 0, 255, 30],
    getTilt: (d) => d.tilt,
    widthUnits: "pixels",
    getWidth: 1,
    getHeight: (d) => d.t * 10000, // Adjust this for desired arc height
  });

  const scatterplotLayerFrom = new ScatterplotLayer({
    id: "scatterplotLayerFrom",
    data: staticArcData,
    pickable: true,
    opacity: 0.8,
    stroked: false,
    filled: true,
    radiusScale: 5,
    radiusMinPixels: 2,
    radiusMaxPixels: 10,
    lineWidthMinPixels: 1,
    getPosition: (d) => [
      parseFloat(d.Item.origin_TownLoc.M.longitude.N),
      parseFloat(d.Item.origin_TownLoc.M.latitude.N),
    ],
    getRadius: (d) => 50,
    getFillColor: (d) => [255, 0, 0],
    getLineColor: (d) => [0, 0, 0],
  });

  const scatterplotLayerTo = new ScatterplotLayer({
    id: "scatterplotLayerTo",
    data: staticArcData,
    pickable: true,
    opacity: 0.8,
    stroked: false,
    filled: true,
    radiusScale: 5,
    radiusMinPixels: 2,
    radiusMaxPixels: 10,
    lineWidthMinPixels: 1,
    getPosition: (d) => [
      parseFloat(d.Item.country1_TownLoc.M.longitude.N),
      parseFloat(d.Item.country1_TownLoc.M.latitude.N),
    ],
    getRadius: (d) => 50,
    getFillColor: (d) => [0, 0, 255],
    getLineColor: (d) => [0, 0, 0],
  });

  useEffect(() => {
    pushToArcData();
    startAnimation();
  }, [timeStepperVal]);

  return (
    <>
      <Paper sx={{ position: "relative", maxWidth: "1024px", width: "100%" }}>
        <Paper onContextMenu={(evt) => evt.preventDefault()}>
          {/* {console.log("ArcMap Rendering")} */}
          <DeckGL
            initialViewState={viewState}
            controller={true}
            animation={true}
            layers={[arcLayerDynamic, arcLayerStatic, scatterplotLayerTo, scatterplotLayerFrom]}
          >
            <InteractiveMap mapLib={maplibregl} mapStyle={mapStyle} />
          </DeckGL>
          <ArcMapControls
            maxTimerVal={maxTimerVal}
            timeStepperVal={timeStepperVal}
            timerRunning={timerRunning}
            startTimer={startTimer}
            stopTimer={stopTimer}
            resetTimer={resetTimer}
            restartTimer={restartTimer}
            placeTimer={placeTimer}
            startDate={startDate}
            endDate={endDate}
          />
        </Paper>
      </Paper>
      <ChartComponent timeStepperVal={timeStepperVal} travelRecords={travelRecords} />
    </>
  );
}
