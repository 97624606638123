import { Button, Typography } from "@mui/material";
import React from "react";
import { createContext } from "react";
import { useNavigate } from "react-router-dom";

const expatFormCtx = createContext({});

function ExpatFormPage() {
  const navigate = useNavigate();

  return (
    <div>
      <Typography variant="h4">Expat Form</Typography>
      <Button onClick={() => navigate("/")}>Go to Landing Page</Button>
    </div>
  );
}

export default ExpatFormPage;
