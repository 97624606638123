import React, { useEffect, useRef } from "react";
import { Paper } from "@mui/material";
import * as d3 from "d3";

function ChartComponent({ timeStepperVal, travelRecords }) {
  const svgRef = useRef(null);

  useEffect(() => {
    // Sample data with 523 data points with values between 0 and 50
    // const data = Array.from({ length: 523 }, () => Math.floor(Math.random() * 51));
    const data = travelRecords.map((record) => record.length);

    const svg = d3.select(svgRef.current);

    // Clear any previous render
    svg.selectAll("*").remove();

    const margin = { top: 20, right: 5, bottom: 30, left: 25 };
    const width = svg.node().getBoundingClientRect().width - margin.left - margin.right;
    const height = 80;
    // const height = svg.node().getBoundingClientRect().height - margin.top - margin.bottom;

    const xScale = d3.scaleBand().domain(d3.range(data.length)).range([0, width]).padding(0);

    const yScale = d3.scaleLinear().domain([0, 10]).range([height, 0]);

    const g = svg.append("g").attr("transform", `translate(${margin.left},${margin.top})`);

    // Horizontal grid lines
    g.append("g")
      .attr("class", "grid")
      .call(d3.axisLeft(yScale).tickValues([5, 10]).tickSize(-width).tickFormat(""))
      .selectAll(".tick line") // Select all the line elements inside the tick
      .attr("stroke", "lightgrey"); // Apply the lightgrey stroke color

    // Draw the bars
    g.selectAll(".bar")
      .data(data)
      .enter()
      .append("rect")
      .attr("class", "bar")
      .attr("x", (d, i) => xScale(i))
      .attr("y", (d) => yScale(d))
      .attr("width", xScale.bandwidth())
      .attr("height", (d) => height - yScale(d))
      .attr("fill", (d, i) => (i === timeStepperVal ? "red" : "steelblue"));

    // Draw the Y Axis with ticks
    g.append("g").call(d3.axisLeft(yScale).tickValues([5, 10]));
  }, [timeStepperVal, travelRecords]); // Redraw when travelRecords change

  return (
    <Paper
      sx={{ height: "100%", marginTop: "80px", maxWidth: "1024px", width: "100%", borderRadius: "0px 0px 4px 4px" }}
    >
      <div style={{ maxWidth: "100%", maxHeight: "100%" }}>
        <svg ref={svgRef} width="100%" height="100%"></svg>
      </div>
    </Paper>
  );
}

export default ChartComponent;
