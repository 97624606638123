import { Paper, Slider, ButtonGroup, IconButton } from "@mui/material";
import React from "react";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/Stop";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import { addMonths, format } from "date-fns";

function ArcMapControls(props) {
  const { timeStepperVal, timerRunning, startTimer, stopTimer, resetTimer, placeTimer, maxTimerVal, startDate } = props;
  return (
    <Paper
      elevation={0}
      sx={{
        position: "absolute",
        bottom: "-80px",
        height: "5rem",
        width: "100%",
        display: "grid",
        justifyItems: "center",
        borderRadius: "0px 0px 0px 0px",
      }}
    >
      <Slider
        value={timeStepperVal}
        onChange={(evt) => placeTimer(evt.target.value)}
        min={0}
        max={maxTimerVal}
        aria-label="Small"
        valueLabelDisplay="on"
        valueLabelFormat={(val) => {
          return format(addMonths(startDate, val), "MMM yyy");
        }}
        sx={{ width: "95%" }}
      />
      <ButtonGroup disableElevation variant="contained" aria-label="Disabled elevation buttons">
        <IconButton
          aria-label="delete"
          size="small"
          sx={{ color: "grey" }}
          onClick={() => {
            resetTimer();
            startTimer();
          }}
        >
          <SkipPreviousIcon />
        </IconButton>
        <IconButton
          aria-label="delete"
          size="small"
          onClick={() => startTimer()}
          sx={{ color: timerRunning ? "#1976d2" : "grey" }}
        >
          <PlayArrowIcon />
        </IconButton>
        <IconButton
          aria-label="delete"
          size="small"
          onClick={() => stopTimer()}
          sx={{
            color: !timerRunning ? "#1976d2" : "grey",
          }}
        >
          <StopIcon />
        </IconButton>
        <IconButton aria-label="delete" size="small" onClick={() => placeTimer(maxTimerVal)} sx={{ color: "grey" }}>
          <SkipNextIcon />
        </IconButton>
      </ButtonGroup>
    </Paper>
  );
}

export default ArcMapControls;
