import { useEffect } from "react";
import { useState } from "react";

export const useTimestepper = (maxTimerVal) => {
  const [timeStepperVal, setTimeStepperVal] = useState(0);
  const [timerRunning, setTimerRunning] = useState(true);
  const [timerSpeed, setTimerSpeed] = useState(1000);

  const startTimer = () => {
    console.log("Starting timer");
    setTimerRunning(true);
  };

  const stopTimer = () => {
    console.log("Stopping timer");
    setTimerRunning(false);
  };

  const resetTimer = () => {
    console.log("Resetting timer");
    setTimeStepperVal(0);
    setTimerRunning(false);
  };

  const restartTimer = () => {
    console.log("Restarting timer");
    setTimeStepperVal(0);
  };

  const placeTimer = (val) => {
    setTimeStepperVal(val);
    stopTimer();
  };

  useEffect(() => {
    let timer = null;
    if (timerRunning) {
      timer = setInterval(() => {
        setTimeStepperVal((prev) => {
          if (prev >= maxTimerVal) {
            stopTimer();
            // restartTimer();
            return prev;
          } else {
            return prev + 1;
          }
        });
      }, timerSpeed);
    }

    return () => clearInterval(timer);
  }, [timeStepperVal, timerRunning]);

  return [timeStepperVal, timerSpeed, timerRunning, startTimer, stopTimer, resetTimer, restartTimer, placeTimer];
};

export function useAnimation(duration) {
  const [animationProgress, setAnimationProgress] = useState(0.00000000000001);

  const startAnimation = () => {
    console.log("STARTING ANIMATION");
    setAnimationProgress(0.00000000000001);

    const startTime = Date.now();

    const animate = () => {
      const elapsed = Date.now() - startTime;
      const newProgress = Math.min(elapsed / duration, 1);

      setAnimationProgress(newProgress);

      if (newProgress < 1) {
        requestAnimationFrame(animate);
      }
    };

    requestAnimationFrame(animate);
  };

  return [animationProgress, startAnimation];
}
