import { southAfricanTowns, globalTowns } from "./towns.js";

export function createTravelRecords() {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // Months are zero-based

  const records = [];

  // Loop through each year from 1980 until the current year
  for (let year = 1980; year <= currentYear; year++) {
    const startMonth = year === 1980 ? 1 : 0; // Start from January for the first year

    // Loop through each month of the year
    for (let month = startMonth; month <= (year === currentYear ? currentMonth : 12); month++) {
      const numRecords = Math.floor(Math.random() * 11); // Random number of records between 0 and 10

      const travelRecords = [];
      // Generate travel records for the month
      for (let i = 0; i < numRecords; i++) {
        const origin = southAfricanTowns[Math.floor(Math.random() * southAfricanTowns.length)];
        const destination = globalTowns[Math.floor(Math.random() * globalTowns.length)];

        const record = {
          Item: {
            year: { N: year.toString() },
            month: { N: month.toString() },
            origin_TownLoc: {
              M: {
                latitude: { N: origin.latitude.toString() },
                longitude: { N: origin.longitude.toString() },
              },
            },
            country1_TownLoc: {
              M: {
                latitude: { N: destination.latitude.toString() },
                longitude: { N: destination.longitude.toString() },
              },
            },
          },
        };

        travelRecords.push(record);
      }

      const monthRecord = travelRecords;
      records.push(monthRecord);
    }
  }

  return records;
}

// export function createTravelRecords() {
//   const currentDate = new Date();
//   const currentYear = currentDate.getFullYear();
//   const currentMonth = currentDate.getMonth() + 1; // Months are zero-based

//   const records = [];

//   // Loop through each year from 1980 until the current year
//   for (let year = 1980; year <= currentYear; year++) {
//     const startMonth = year === 1980 ? 1 : 0; // Start from January for the first year

//     // Loop through each month of the year
//     for (let month = startMonth; month <= (year === currentYear ? currentMonth : 12); month++) {
//       const numRecords = Math.floor(Math.random() * 11); // Random number of records between 0 and 10

//       const travelRecords = [];
//       // Generate travel records for the month
//       for (let i = 0; i < numRecords; i++) {
//         const originLatitude = (-30 - Math.random() * 10).toFixed(2);
//         const originLongitude = (25 + Math.random() * 10).toFixed(2);
//         const country1Latitude = (-45 - Math.random() * 10).toFixed(2);
//         const country1Longitude = (165 + Math.random() * 20).toFixed(2);

//         const record = {
//           Item: {
//             year: { N: year.toString() },
//             month: { N: month.toString() },
//             origin_TownLoc: {
//               M: {
//                 latitude: { N: originLatitude },
//                 longitude: { N: originLongitude },
//               },
//             },
//             country1_TownLoc: {
//               M: {
//                 latitude: { N: country1Latitude },
//                 longitude: { N: country1Longitude },
//               },
//             },
//           },
//         };

//         travelRecords.push(record);
//       }

//       const monthRecord = travelRecords;
//       records.push(monthRecord);
//     }
//   }

//   return records;
// }
